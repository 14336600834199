import { useEffect, useState } from "react";
import { Header } from "semantic-ui-react";
import * as firebaseApi from "../api/firebase";
import WitchingsList from "../common/WitchingsList";

function useUserFirebaseQuery(seasonId, user, fetchMethod) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user)
      return fetchMethod(seasonId, user.id, (data) => {
        setData(data);
        setIsLoading(false);
      });
  }, [user, fetchMethod, seasonId]);

  return [data, isLoading];
}

function useUserRecentWitchings(seasonId, user) {
  const [playerIdWitchings, isLoadingPlayerId] = useUserFirebaseQuery(
    seasonId,
    user,
    firebaseApi.subscribeToWitchingsByPlayerId
  );
  const [targetIdWitchings, isLoadingTargetId] = useUserFirebaseQuery(
    seasonId,
    user,
    firebaseApi.subscribeToWitchingsByTargetId
  );

  const [recentWitchings, setRecentWitchings] = useState([]);
  useEffect(() => {
    try {
      const combinedWitchings = [...playerIdWitchings, ...targetIdWitchings]
        .sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis())
        .slice(0, 5);

      setRecentWitchings(combinedWitchings);
    } catch (error) {}
  }, [playerIdWitchings, targetIdWitchings, seasonId]);

  return [isLoadingPlayerId || isLoadingTargetId, recentWitchings];
}

export default function RecentWitchings({ user, seasonId }) {
  const [isLoading, recentWitchings] = useUserRecentWitchings(seasonId, user);

  return (
    <>
      <Header as="h2" className="recentWitchings">
        Recent Witchings
      </Header>
      <WitchingsList loading={isLoading} witchings={recentWitchings} />
    </>
  );
}
