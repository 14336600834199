import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { Router } from "@reach/router";
import App from "./App";
import BountiesPage from "./bounties/BountiesPage";
import HomePage from "./home/HomePage";
import ProfilePage from "./profile/ProfilePage";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App path="/">
        <HomePage path="/" />
        <ProfilePage path="/profile" />
        <BountiesPage path="/bounties" />
      </App>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
