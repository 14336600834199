import { useContext, useEffect, useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import * as firebaseApi from "../api/firebase";
import UserContext from "../common/UserContext";
import * as toast from "../toast";

export default function NewBounty() {
  const { user } = useContext(UserContext);

  const [userNames, setUserNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [targetId, setTargetId] = useState(null);
  const [targetIdError, setTargetIdError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [rewardAmount, setRewardAmount] = useState(0);
  const [rewardAmountError, setRewardAmountError] = useState(false);

  useEffect(() => {
    setTargetId(null);
    setTargetIdError(null);
    setRewardAmountError(false);
    setRewardAmount(0);
  }, [isOpen]);

  useEffect(() => {
    async function getUserNames() {
      const names = await firebaseApi.getUserNames();
      if (!names) {
        toast.error("Something went wrong, please try again later. 😥");
      }
      setUserNames(names);
      setIsLoading(false);
    }
    getUserNames();
  }, [isOpen]);

  async function onNewBountyClick() {
    if (user.activeBounties >= 2) {
      toast.info("You can only have 2 active bounties.");
    } else {
      setIsOpen(true);
    }
  }

  async function handleSubmit() {
    let hasErrors = false;
    if (!targetId) {
      setTargetIdError({
        content: "Please select your witching target",
        pointing: "above",
      });
      hasErrors = true;
    } else setTargetIdError(null);

    if (rewardAmount === 0) {
      setRewardAmountError({
        content: "Please enter reward amount",
        pointing: "above",
      });
      hasErrors = true;
    } else setRewardAmountError(null);

    if (hasErrors) return;

    setIsLoading(true);
    const result = await firebaseApi.addBounty(targetId, rewardAmount);
    setIsLoading(false);
    if (!result) {
      toast.error("An error has occured, please try again.");
    } else {
      setIsOpen(false);
      toast.success("Bounty created successfully!");
    }
  }

  return (
    <>
      <Button inverted color="brown" onClick={onNewBountyClick}>
        <Icon name="dollar" /> New Bounty
      </Button>
      <Modal open={isOpen} size="mini">
        <Modal.Header>New Bounty</Modal.Header>
        <Modal.Content>
          <Form loading={isLoading}>
            <Form.Field>
              <label>Target</label>
              <Form.Dropdown
                options={userNames}
                fluid
                selection
                placeholder="Target player"
                search
                onChange={(e, data) => setTargetId(data.value)}
                error={targetIdError}
              />
            </Form.Field>
            <Form.Field>
              <label>Reward (Gold)</label>
              <Form.Input
                fluid
                error={rewardAmountError}
                type="number"
                placeholder="Gold Reward"
                onChange={(e, data) => setRewardAmount(Number(data.value))}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary disabled={isLoading} onClick={handleSubmit}>
            Submit
          </Button>
          <Button disabled={isLoading} onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
