import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "firebase/storage";

import firebase from "firebase/app";
import { v4 as uuidv4 } from "uuid";

const config = {
  apiKey: "AIzaSyC6Nekx8GIBMsbfPR4dLN1IcDdMVSnVzlw",
  authDomain: "the-coven-9844f.firebaseapp.com",
  projectId: "the-coven-9844f",
  storageBucket: "the-coven-9844f.appspot.com",
  messagingSenderId: "668266061984",
  appId: "1:668266061984:web:a7e773d306c8e51fb969c5",
};

const isDev = process.env.NODE_ENV !== "production";

if (!firebase.apps.length) {
  firebase.initializeApp(config);

  if (isDev) {
    firebase.auth().useEmulator("http://localhost:9099");
    firebase.firestore().useEmulator("localhost", 8080);
    firebase.functions().useEmulator("localhost", 5001);
  }
} else {
  firebase.app(); // if already initialized, use that one
}

export async function loginUser(loginDetails) {
  const { login_token, ...userDetails } = JSON.parse(atob(loginDetails));

  try {
    const user = await firebase.auth().signInWithCustomToken(login_token);
    const userId = user.user.uid;
    const docRef = firebase.firestore().collection("users").doc(userId);
    const doc = await docRef.get();

    if (doc.exists) {
      return { isNewUser: false, error: false };
    } else {
      // registering user
      const { tokens, ...restDetails } = userDetails;
      docRef.set({
        ...restDetails,
        witchingTargets: {},
        witchedBy: {},
        witchingsCount: 0,
        timesWitched: 0,
      });
      docRef.collection("private").doc("tokens").set(tokens);
      return { isNewUser: true, error: false };
    }
  } catch (error) {
    console.error(error);
    return { isNewUser: false, error: true };
  }
}

export async function logoutUser() {
  try {
    await firebase.auth().signOut();
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

const usersCache = {};

/**
 * Gets user data, uses cache unless forceUpdae is true
 * @param {*} id userId
 * @param {*} forceUpdate when true fetches data from the server, else uses cache
 * @returns the user's data from db
 */
export async function getUserData(id, forceUpdate = false) {
  if (!forceUpdate && usersCache[id]) {
    return { ...usersCache[id] };
  }

  try {
    const user = await firebase.firestore().collection("users").doc(id).get();
    usersCache[id] = user.data();
    return usersCache[id];
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getSeasonalUserData(seasonId, userId) {
  try {
    const user = await firebase
      .firestore()
      .collection(`seasonalStats/${seasonId}/userStats`)
      .doc(userId)
      .get();
    return user.data();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function subscribeToAuthState(callback) {
  return firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      callback(user.uid);
    } else {
      callback(null);
    }
  });
}

export async function setUserDetails(
  characterName,
  characterClass,
  realm,
  characterImage
) {
  try {
    const user = firebase.auth().currentUser;
    await firebase.firestore().collection("users").doc(user.uid).set(
      {
        characterName,
        characterClass,
        realm,
        characterImage,
      },
      { merge: true }
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getUserNames() {
  try {
    const querySnapshot = await firebase.firestore().collection("users").get();
    let userNames = [];
    const currentUserId = firebase.auth().currentUser.uid;
    querySnapshot.forEach((doc) => {
      const user = doc.data();
      if (user.id !== currentUserId && user.characterName !== undefined)
        userNames.push({
          key: user.id,
          value: user.id,
          text: user.characterName,
        });
    });
    return userNames.sort((a, b) => compareStrings(a.text, b.text));
  } catch (error) {
    console.error(error);
    return null;
  }
}

function compareStrings(a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
}

export async function uploadImage(image) {
  if (isDev)
    return "https://firebasestorage.googleapis.com/v0/b/the-coven-9844f.appspot.com/o/evidence%2F0b3eeeb8-5403-41da-a9fa-305a64efe493.jpg?alt=media&token=b632b7bc-1e69-4b24-a92e-ac90975a8026";

  try {
    const imageName = uuidv4() + "." + image.name.split(".").pop();
    const result = await firebase
      .storage()
      .ref()
      .child("evidence/" + imageName)
      .put(image);
    const url = await result.ref.getDownloadURL();
    return url;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function addWitching(targetId, image, bountyId = null) {
  const firebaseUser = firebase.auth().currentUser;

  try {
    const imageUrl = await uploadImage(image);
    if (!imageUrl) return false;

    const addWitchingFirebase = firebase
      .functions()
      .httpsCallable("addWitching");

    await addWitchingFirebase({
      playerId: firebaseUser.uid,
      targetId,
      evidence: imageUrl,
      ...(bountyId && { bountyId }),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export function getCurrentSeason() {
  return firebase
    .firestore()
    .collection("stats")
    .doc("currentSeason")
    .get()
    .then((result) => result.data());
}

export async function getAllSeasons() {
  const seasonList = await firebase
    .firestore()
    .collection("stats")
    .doc("seasonList")
    .get();
  return seasonList.data().seasons;
}

export function subscribeToUserData(callback) {
  return firebase
    .firestore()
    .collection("users")
    .doc(firebase.auth().currentUser.uid)
    .onSnapshot((querySnapshot) => {
      callback(querySnapshot.data());
    });
}

export function subscribeToRecentWitchings(seasonId, callback) {
  return firebase
    .firestore()
    .collection("seasonalStats")
    .doc(seasonId)
    .collection("witchings")
    .orderBy("timestamp", "desc")
    .limit(5)
    .onSnapshot(async (querySnapshot) => {
      const witchings = await parseUserBasedQueryData(querySnapshot);
      callback(witchings);
    });
}

async function parseUserBasedQueryData(querySnapshot) {
  let rawDocs = [];
  querySnapshot.forEach((doc) => {
    rawDocs.push({ id: doc.id, ...doc.data() });
  });

  let docs = [];
  for (const raw of rawDocs) {
    const { targetId, playerId, ...restData } = raw;
    const targetUser = await getUserData(raw.targetId);
    const playerUser = await getUserData(raw.playerId);

    const data = {
      targetUser,
      playerUser,
      ...restData,
    };

    docs.push(data);
  }

  return docs;
}

export function subscribeToTopWitchers(seasonId, callback) {
  return firebase
    .firestore()
    .collection(`seasonalStats/${seasonId}/userStats`)
    .orderBy("points", "desc")
    .limit(5)
    .onSnapshot(async (querySnapshot) => {
      let seasonalData = [];
      querySnapshot.forEach((doc) => {
        seasonalData.push({ id: doc.id, ...doc.data() });
      });

      let fullData = [];
      for (const userSeasonalData of seasonalData) {
        const userData = await getUserData(userSeasonalData.id);
        fullData.push({
          ...userData,
          ...userSeasonalData,
          wanted: userSeasonalData.points === seasonalData[0].points,
        });
      }

      callback(fullData);
    });
}

export function subscribeToMostWitched(seasonId, callback) {
  return firebase
    .firestore()
    .collection(`seasonalStats/${seasonId}/userStats`)
    .orderBy("timesWitched", "desc")
    .limit(5)
    .onSnapshot(async (querySnapshot) => {
      let seasonalData = [];
      querySnapshot.forEach((doc) => {
        seasonalData.push({ id: doc.id, ...doc.data() });
      });

      let fullData = [];
      for (const userSeasonalData of seasonalData) {
        const userData = await getUserData(userSeasonalData.id);
        fullData.push({ ...userData, ...userSeasonalData });
      }

      callback(fullData);
    });
}

export function subscribeToWitchingsByPlayerId(seasonId, userId, callback) {
  return firebase
    .firestore()
    .collection("seasonalStats")
    .doc(seasonId)
    .collection("witchings")
    .where("playerId", "==", userId)
    .orderBy("timestamp", "desc")
    .limit(5)
    .onSnapshot(async (querySnapshot) => {
      const witchings = await parseUserBasedQueryData(querySnapshot);
      callback(witchings);
    });
}

export function subscribeToWitchingsByTargetId(seasonId, userId, callback) {
  return firebase
    .firestore()
    .collection("seasonalStats")
    .doc(seasonId)
    .collection("witchings")
    .where("targetId", "==", userId)
    .orderBy("timestamp", "desc")
    .limit(5)
    .onSnapshot(async (querySnapshot) => {
      const witchings = await parseUserBasedQueryData(querySnapshot);
      callback(witchings);
    });
}

export async function addBounty(targetId, reward) {
  try {
    const addBountyFirebase = firebase.functions().httpsCallable("addBounty");

    await addBountyFirebase({
      targetId,
      playerId: firebase.auth().currentUser.uid,
      reward,
    });

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function subscribeToBounties(callback) {
  return firebase
    .firestore()
    .collection("bounties")
    .orderBy("timestamp", "desc")
    .onSnapshot(async (querySnapshot) => {
      const bounties = await parseUserBasedQueryData(querySnapshot);

      for (let i = 0; i < bounties.length; i++) {
        if (bounties[i].claimedBy)
          bounties[i].claimedUser = await getUserData(bounties[i].claimedBy);
      }

      callback(bounties);
    });
}

export async function getCharacterImage(characterName, realm) {
  try {
    const getPlayerPicture = firebase
      .functions()
      .httpsCallable("getPlayerPicture");

    const result = await getPlayerPicture({
      realm,
      characterName,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function confirmBountyReward(bounty) {
  try {
    const confirmBounty = firebase
      .functions()
      .httpsCallable("confirmBountyReward");

    await confirmBounty({
      bountyId: bounty.id,
      playerId: bounty.playerUser.id,
      claimedBy: bounty.claimedBy,
      reward: bounty.reward,
    });

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function deleteBounty(bountyId) {
  try {
    await firebase.firestore().collection("bounties").doc(bountyId).delete();
    await firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .set(
        { activeBounties: firebase.firestore.FieldValue.increment(-1) },
        { merge: true }
      );

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getCharacterData(characterName, realm) {
  try {
    const getData = firebase.functions().httpsCallable("getCharacterData");

    const result = await getData({
      realm,
      characterName,
    });

    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getOpenBounties() {
  const userId = firebase.auth().currentUser.uid;

  try {
    const result = await firebase
      .firestore()
      .collection("bounties")
      .where("status", "==", "open")
      .where("playerId", "!=", userId)
      .get();

    const parsed = await parseUserBasedQueryData(result);

    return parsed;
  } catch (error) {
    console.error(error);
    return false;
  }
}
