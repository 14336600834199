import { Header } from "semantic-ui-react";
import logo from "../images/logo.png";

export default function MyHeader() {
  return (
    <Header inverted className="logo" textAlign="center">
      <img src={logo} alt="The Coven" />
      <h1>The Coven</h1>
      <Header.Subheader as="h2" className="slogan">
        24/7 witching
      </Header.Subheader>
    </Header>
  );
}
