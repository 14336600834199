import { List } from "semantic-ui-react";
import PlaceholderContent from "./PlaceholderContent";
import WitchingItem from "./WitchingItem";

export default function WitchingsList({ loading, witchings }) {
  if (loading) return <PlaceholderContent />;

  if (witchings.length === 0)
    return (
      <div className="emptyWitchings" key="empty">
        No witchings, yet...
      </div>
    );

  return (
    <List>
      {witchings.map((witching) => (
        <List.Item className="recentWitchingItem" key={witching.timestamp}>
          <WitchingItem {...witching} />
        </List.Item>
      ))}
    </List>
  );
}
