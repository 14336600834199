import { useContext, useEffect, useState } from "react";
import { Button, Card, Container, Grid, Label } from "semantic-ui-react";
import * as firebaseApi from "../api/firebase";
import PlaceholderContent from "../common/PlaceholderContent";
import UserContext from "../common/UserContext";
import * as toast from "../toast";
import BountyCard from "./BountyCard";

export default function BountiesPage() {
  const { user } = useContext(UserContext);
  const [bounties, setBounties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    return firebaseApi.subscribeToBounties((data) => {
      setIsLoading(false);
      setBounties(data);
    });
  }, []);

  async function onDelete(bounty) {
    const result = await firebaseApi.deleteBounty(bounty.id);
    if (result) {
      toast.success("Bounty deleted successfully!");
    } else {
      toast.error("An error has occured, please try again.");
    }
  }

  const [tab, setTab] = useState("open");

  let filteredBounties = [];
  if (tab === "open")
    filteredBounties = bounties.filter((bounty) => bounty.status === "open");
  else if (tab === "my")
    filteredBounties = bounties.filter(
      (bounty) => bounty.playerUser.id === user.id
    );
  else if (tab === "claimed")
    filteredBounties = bounties.filter(
      (bounty) => bounty.claimedBy === user.id
    );

  const claimedPendingBountyCount = bounties.filter(
    (bounty) => bounty.claimedBy === user.id && bounty.status === "pending"
  ).length;

  return (
    <Container className="bountiesPage">
      <Grid inverted textAlign="center" stackable>
        <Grid.Row color="black" columns={1}>
          <Button
            inverted
            active={tab === "open"}
            className="openBountiesBtn"
            size="large"
            onClick={() => setTab("open")}
          >
            Open Bounties
          </Button>
          <Button
            inverted
            active={tab === "claimed"}
            className="openBountiesBtn"
            size="large"
            onClick={() => setTab("claimed")}
          >
            {claimedPendingBountyCount > 0 && (
              <Label
                color="red"
                circular
                size="large"
                className="claimedBountiesBadge"
              >
                {claimedPendingBountyCount}
              </Label>
            )}
            Claimed Bounties
          </Button>
          <Button
            inverted
            size="large"
            onClick={() => setTab("my")}
            active={tab === "my"}
          >
            My Bounties
          </Button>
        </Grid.Row>
        <Grid.Row color="black" columns={1}>
          <Grid.Column>
            {isLoading && <PlaceholderContent />}
            {!isLoading && filteredBounties.length === 0 && (
              <div className="emptyWitchings">No Bounties, yet...</div>
            )}
            <Card.Group centered className="bountiesGroup" itemsPerRow="4">
              {filteredBounties.map((bounty) => (
                <BountyCard
                  bounty={bounty}
                  key={bounty.id}
                  user={user}
                  onDelete={onDelete}
                />
              ))}
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
