import { Placeholder, Segment } from "semantic-ui-react";

export default function PlaceholderContent() {
  return (
    <Segment inverted>
      <Placeholder inverted className="recentWitchingsPlaceholder">
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    </Segment>
  );
}
