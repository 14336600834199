import { useContext, useEffect, useState } from "react";
import * as firebaseApi from "../api/firebase";
import LeaderboardTable from "../common/LeaderboardTable";
import SeasonContext from "../common/SeasonContext";

export default function PersonalWitchingsTable({
  user,
  property,
  totalProperty,
}) {
  const season = useContext(SeasonContext);
  const [data, setData] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    async function updateData() {
      const seasonalUserData = await firebaseApi.getSeasonalUserData(
        season.id,
        user.id
      );

      if (!seasonalUserData) {
        setData([]);
        setTotalValue(0);
        return;
      }

      let fullData = [];
      for (const userId in seasonalUserData[property]) {
        const userData = await firebaseApi.getUserData(userId);
        userData.score = seasonalUserData[property][userId];
        fullData = [...fullData, userData];
      }

      fullData.sort((a, b) => b.score - a.score);
      setData(fullData);
      setTotalValue(seasonalUserData[totalProperty] || 0);
    }
    updateData();
  }, [user, property, season, setTotalValue, totalProperty]);

  return (
    <LeaderboardTable
      data={data}
      property="score"
      footer={{ name: "Total", value: totalValue }}
      minRows={5}
      paged
    />
  );
}
