import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Icon,
  Image,
  Menu,
  MenuItem,
  Statistic,
} from "semantic-ui-react";
import { useLocation, useNavigate } from "@reach/router";
import * as firebaseApi from "../api/firebase";
import UserContext from "../common/UserContext";
import * as toast from "../toast";
import NewBounty from "./NewBounty";
import ReportWitching from "./ReportWitching";

export default function TopMenu({ changeSeason, season }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, seasonalUserData } = useContext(UserContext);

  const [seasons, setSeasons] = useState([]);
  useEffect(() => {
    async function getSeasons() {
      const seasonList = await firebaseApi.getAllSeasons();
      const formattedSeasons = [];
      for (const season of seasonList) {
        formattedSeasons.push({
          key: season.id,
          text: season.name,
          value: season.id,
        });
      }

      setSeasons(formattedSeasons);
    }
    getSeasons();
  }, [setSeasons]);

  function handleLogin() {
    const prodAuthUrl =
      "https://discord.com/api/oauth2/authorize?client_id=824634647010607127&redirect_uri=https%3A%2F%2Fus-central1-the-coven-9844f.cloudfunctions.net%2FauthCallback&response_type=code&scope=identify";
    const devAuthUrl =
      "https://discord.com/api/oauth2/authorize?client_id=824634647010607127&redirect_uri=http%3A%2F%2Flocalhost%3A5001%2Fthe-coven-9844f%2Fus-central1%2FauthCallback&response_type=code&scope=identify";

    window.location =
      process.env.NODE_ENV === "production" ? prodAuthUrl : devAuthUrl;
  }

  async function handleLogout() {
    const isSuccessful = await firebaseApi.logoutUser();
    if (isSuccessful) navigate("/");
    else toast.error("Error while logging out, please try again.");
  }

  function onSeasonChange(e, data) {
    const newSeason = seasons.find((season) => season.key === data.value);
    changeSeason({ id: newSeason.value, name: newSeason.text });
  }

  return (
    <>
      <Menu inverted secondary className="menu">
        {user && (
          <>
            <MenuItem
              onClick={() => navigate("/")}
              active={location.pathname === "/"}
            >
              <Icon name="home" size="big" className="menuHomeIcon" />
            </MenuItem>
            <MenuItem
              active={location.pathname === "/profile"}
              className="menuHello"
              onClick={() => navigate("/profile")}
            >
              <Image
                src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`}
                avatar
              />
              <span className="userName">{user.username}</span>(
              <span className={clsx("userCharacter", user.characterClass)}>
                {user.characterName}
              </span>
              )
            </MenuItem>

            {seasonalUserData && (
              <MenuItem>
                <Statistic inverted size="tiny">
                  <Statistic.Value>{seasonalUserData.points}</Statistic.Value>
                  <Statistic.Label className="witchingStat">
                    Season Score
                  </Statistic.Label>
                </Statistic>
              </MenuItem>
            )}

            {/* <MenuItem>
              <Statistic inverted size="tiny">
                <Statistic.Value>{user.witchingsCount}</Statistic.Value>
                <Statistic.Label className="witchingStat">
                  Total
                  <br />
                  Witchings
                </Statistic.Label>
              </Statistic>
            </MenuItem> */}

            <MenuItem
              active={location.pathname === "/bounties"}
              className="bountiesMenuItem"
              onClick={() => navigate("/bounties")}
            >
              <Icon name="dollar" className="bountyIcon" />
              Bounties
            </MenuItem>

            <Dropdown
              className="seasonDropdown"
              options={seasons}
              pointing
              item
              value={season.id}
              onChange={onSeasonChange}
            />
          </>
        )}
        <Menu.Menu position="right">
          {!user && (
            <MenuItem>
              <Button
                inverted
                className="loginBtn"
                color="violet"
                onClick={handleLogin}
              >
                <Icon name="discord" size="large" />
                Login
              </Button>
            </MenuItem>
          )}
          {user && (
            <>
              <MenuItem>
                <ReportWitching />
              </MenuItem>
              <MenuItem>
                <NewBounty />
              </MenuItem>
              <MenuItem>
                <Button inverted onClick={handleLogout}>
                  Logout
                </Button>
              </MenuItem>
            </>
          )}
        </Menu.Menu>
      </Menu>
    </>
  );
}
