import clsx from "clsx";
import { useState } from "react";
import { Button, Card, Icon, Image } from "semantic-ui-react";
import * as firebaseApi from "../api/firebase";
import ConfirmModal from "../common/ConfirmModal";
import * as toast from "../toast";
import ClaimModal from "./ClaimModal";

export default function BountyCard({ bounty, user, onDelete }) {
  const { playerUser, targetUser, reward, status, claimedBy, claimedUser } =
    bounty;

  const [isClaimModalOpen, setIsClaimModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  async function onConfirmReward() {
    const result = await firebaseApi.confirmBountyReward(bounty);

    if (result) {
      toast.success("Reward confirmed successfully!");
      setIsConfirmModalOpen(false);
    } else {
      toast.error("An error has occured, please try again.");
    }
  }

  let footer;

  if (status === "open")
    footer = (
      <Button
        basic
        inverted
        onClick={() => setIsClaimModalOpen(true)}
        disabled={
          user && (user.id === playerUser.id || user.id === targetUser.id)
        }
      >
        Claim
      </Button>
    );
  else if (status === "pending") {
    if (claimedBy === user.id)
      footer = (
        <Button basic inverted onClick={() => setIsConfirmModalOpen(true)}>
          Confirm Reward
        </Button>
      );
    else
      footer = (
        <div>
          Waiting for{" "}
          <span className={claimedUser.characterClass}>
            {claimedUser.characterName}
          </span>{" "}
          to confirm their reward
        </div>
      );
  } else if (status === "closed") {
    footer = <div className="bountyClaimedFooter">Claimed</div>;
  }

  return (
    <>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        text="Did you receive your reward?"
        closeModal={() => setIsConfirmModalOpen(false)}
        onConfirm={onConfirmReward}
      />
      <ClaimModal
        isOpen={isClaimModalOpen}
        closeModal={() => setIsClaimModalOpen(false)}
        bounty={bounty}
      />
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        text="Are you sure you want to delete this bounty?"
        closeModal={() => setIsDeleteModalOpen(false)}
        onConfirm={() => onDelete(bounty)}
      />
      <Card className="bountyCard">
        <Card.Content>
          <Card.Header>
            <h1 className={clsx("bountyTarget", targetUser.characterClass)}>
              {targetUser.characterName}
            </h1>
          </Card.Header>
          <Card.Description>
            {user && playerUser.id === user.id && status === "open" && (
              <Button
                icon="x"
                color="red"
                circular
                className="deleteBounty"
                size="large"
                onClick={() => setIsDeleteModalOpen(true)}
              />
            )}

            {targetUser.characterImage ? (
              <Image src={targetUser.characterImage} />
            ) : (
              <Icon
                name="user"
                inverted
                size="huge"
                className="bountyImagePlaceholder"
              />
            )}

            <div className="bountyText">
              Reward from{" "}
              <span className={playerUser.characterClass}>
                {playerUser.characterName}
              </span>
            </div>
            <h4 className="bountyGold">
              {reward.toLocaleString("en-US")} Gold
            </h4>
          </Card.Description>
        </Card.Content>
        <Card.Content extra className="bountyFooter">
          {footer}
        </Card.Content>
      </Card>
    </>
  );
}
