import { useContext, useEffect, useState } from "react";
import * as firebaseApi from "../api/firebase";
import LeaderboardTable from "../common/LeaderboardTable";
import SeasonContext from "../common/SeasonContext";

export default function TopWitchers() {
  const season = useContext(SeasonContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!season) return;
    return firebaseApi.subscribeToTopWitchers(season.id, setData);
  }, [season]);

  return <LeaderboardTable data={data} property="points" />;
}
