import { useContext, useEffect } from "react";
import { Container, Grid, Header, Image } from "semantic-ui-react";
import { useNavigate } from "@reach/router";
import SeasonContext from "../common/SeasonContext";
import UserContext from "../common/UserContext";
import PersonalWitchingsTable from "./PersonalWitchingsTable";
import RecentWitchings from "./RecentWitchings";

export default function ProfilePage() {
  const { user, isLoading } = useContext(UserContext);
  const season = useContext(SeasonContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !user) navigate("/");
  }, [user, isLoading, navigate]);

  if (isLoading || !user) return null;

  return (
    <Container className="profilePage">
      <Grid inverted textAlign="center" stackable>
        <Grid.Row color="black" columns={1}>
          <Grid.Column>
            <Header as="h1" className="profileHeader">
              <Image
                src={
                  require(`../images/class-icons/${user.characterClass}.png`)
                    .default
                }
              />
              <span className={user.characterClass}>{user.characterName}</span>
            </Header>
            <RecentWitchings user={user} seasonId={season.id} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row color="black" columns={2} className="tables">
          <Grid.Column textAlign="left">
            <Header as="h3" inverted>
              My Witches
            </Header>
            <PersonalWitchingsTable
              user={user}
              property="witchingTargets"
              totalProperty="witchingsCount"
            />
          </Grid.Column>
          <Grid.Column textAlign="left">
            <Header as="h3" inverted>
              Witched Me
            </Header>
            <PersonalWitchingsTable
              user={user}
              property="witchedBy"
              totalProperty="timesWitched"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
