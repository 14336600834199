import { toast } from "react-toastify";
import { Icon } from "semantic-ui-react";

export function success(message) {
  toast.success(
    <>
      <Icon name="check" style={{ marginRight: ".5rem" }} />
      {message}
    </>
  );
}

export function error(message) {
  toast.error(
    <>
      <Icon name="exclamation" style={{ marginRight: ".5rem" }} />
      {message}
    </>
  );
}

export function info(message) {
  toast.info(
    <>
      <Icon name="info" style={{ marginRight: ".5rem" }} />
      {message}
    </>
  );
}
