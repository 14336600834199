import "./App.css";

import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Dimmer, Loader } from "semantic-ui-react";
import * as firebaseApi from "./api/firebase";
import SeasonContext from "./common/SeasonContext";
import UserContext from "./common/UserContext";
import RegisterModal from "./home/RegisterModal";
import TopMenu from "./home/TopMenu";
import * as toast from "./toast";

function App({ children }) {
  const [showRegister, setShowRegister] = useState(false);
  const [isProcessingLogin, setIsProcessingLogin] = useState(true);

  useEffect(() => {
    async function handleLogin() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const loginDetails = urlParams.get("login");

      if (loginDetails) {
        if (loginDetails === "error")
          toast.error("Error while signing in, please try again.");
        else {
          const { isNewUser, error } = await firebaseApi.loginUser(
            loginDetails
          );
          window.history.replaceState({}, document.title, "/");
          setShowRegister(isNewUser);
          if (error) toast.error("Error while signing in, please try again.");
        }
      }

      setIsProcessingLogin(false);
    }
    handleLogin();
  }, []);

  const [currentSeason, setCurrentSeason] = useState(null); // this is the current active season in the server, not the user selected one
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [seasonalUserData, setSeasonalUserData] = useState(null);
  useEffect(() => {
    let unsubscribeUserData;
    const unsubscribeAuth = firebaseApi.subscribeToAuthState((userId) => {
      if (userId) {
        if (unsubscribeUserData) unsubscribeUserData();
        unsubscribeUserData = firebaseApi.subscribeToUserData(
          async (userData) => {
            setUser(userData);

            if (currentSeason) {
              const seasonalData = await firebaseApi.getSeasonalUserData(
                currentSeason.id,
                userId
              );
              setSeasonalUserData(seasonalData);
              setIsLoading(false);
            }
          }
        );
      } else if (!isProcessingLogin) {
        setUser(null);
        setIsLoading(false);
      }
    });
    return () => {
      unsubscribeAuth();
      if (unsubscribeUserData) unsubscribeUserData();
    };
  }, [isProcessingLogin, currentSeason]);

  useEffect(() => {
    if (!showRegister && user && !user.realm) {
      setShowRegister(true);
    }
  }, [showRegister, user]);

  const [season, setSeason] = useState(null);
  useEffect(() => {
    async function getCurrentSeason() {
      const currentSeason = await firebaseApi.getCurrentSeason();
      setSeason(currentSeason);
      setCurrentSeason(currentSeason);
    }
    getCurrentSeason();
  }, [setSeason]);

  function changeSeason(season) {
    setSeason(season);
  }

  return (
    <UserContext.Provider value={{ user, isLoading, seasonalUserData }}>
      <SeasonContext.Provider value={season}>
        <ToastContainer hideProgressBar position="top-center" />
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        {showRegister && <RegisterModal user={user} />}
        <TopMenu changeSeason={changeSeason} season={season} />
        {children}
      </SeasonContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
