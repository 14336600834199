import { Container, Grid, Header as SemanticHeader } from "semantic-ui-react";
import Header from "./Header";
import MostWitched from "./MostWitched";
import RecentWitchings from "./RecentWitchings";
import TopWitchers from "./TopWitchers";

export default function HomePage() {
  return (
    <Container>
      <Header />
      <Grid inverted textAlign="center" stackable>
        <Grid.Row color="black" columns={1}>
          <Grid.Column>
            <SemanticHeader as="h2" className="recentWitchings">
              Latest Witchings
            </SemanticHeader>
            <RecentWitchings />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row color="black" columns={2} className="tables">
          <Grid.Column textAlign="left">
            <SemanticHeader as="h3" inverted>
              Top Witchers
            </SemanticHeader>
            <TopWitchers />
          </Grid.Column>
          <Grid.Column textAlign="left">
            <SemanticHeader as="h3" inverted>
              Real Witches
            </SemanticHeader>
            <MostWitched />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
