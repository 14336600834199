import { useState } from "react";
import { Icon, Pagination, Popup, Table } from "semantic-ui-react";

export default function LeaderboardTable({
  data,
  property,
  footer,
  minRows,
  paged,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(e, { activePage }) {
    setCurrentPage(activePage);
  }

  let pagedData = data;
  if (paged) {
    const index = (currentPage - 1) * minRows;
    pagedData = data.slice(index, index + minRows);
  }

  return (
    <Table inverted basic="very" size="large" className="statTable">
      <Table.Body>
        {pagedData.map((user) => (
          <Table.Row key={user.id}>
            <Table.Cell style={{ position: "relative" }}>
              {user.wanted && (
                <Popup
                  trigger={<Icon name="exclamation" className="wantedIcon" />}
                  content="Witch this target to gain double points!"
                  position="left center"
                  wide
                />
              )}
              <span className={user.characterClass}>{user.characterName}</span>
            </Table.Cell>
            <Table.Cell textAlign="center">{user[property]}</Table.Cell>
          </Table.Row>
        ))}
        {minRows &&
          pagedData.length < minRows &&
          [...Array(minRows - pagedData.length)].map((_, index) => (
            <Table.Row key={index}>
              <Table.Cell> &nbsp;</Table.Cell>
              <Table.Cell> &nbsp;</Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
      <Table.Footer>
        {footer && (
          <Table.Row className="tableFooter">
            <Table.HeaderCell>{footer.name}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              {footer.value}
            </Table.HeaderCell>
          </Table.Row>
        )}
        {paged && data.length > minRows && (
          <Table.Row>
            <Table.HeaderCell colSpan="2">
              <Pagination
                className="tablePagination"
                value={currentPage}
                pointing
                secondary
                inverted
                size="large"
                firstItem={null}
                lastItem={null}
                totalPages={Math.ceil(data.length / minRows)}
                boundaryRange={0}
                siblingRange={2}
                onPageChange={handlePageChange}
                nextItem={{
                  icon: true,
                  content: <Icon name="chevron right" />,
                }}
                prevItem={{
                  icon: true,
                  content: <Icon name="chevron left" />,
                }}
              />
            </Table.HeaderCell>
          </Table.Row>
        )}
      </Table.Footer>
    </Table>
  );
}
