import { useContext, useEffect, useState } from "react";
import * as firebaseApi from "../api/firebase";
import SeasonContext from "../common/SeasonContext";
import WitchingsList from "../common/WitchingsList";

export default function RecentWitchings() {
  const season = useContext(SeasonContext);
  const [witchings, setWitchings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // TODO: maybe move state up so we dont have to resubscribe on every page change.
  useEffect(() => {
    if (!season) return;

    return firebaseApi.subscribeToRecentWitchings(season.id, (witchings) => {
      setIsLoading(false);
      setWitchings(witchings);
    });
  }, [season]);

  return <WitchingsList loading={isLoading} witchings={witchings} />;
}
