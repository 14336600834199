import clsx from "clsx";
import { DateTime } from "luxon";
import { useState } from "react";
import { Icon, Image, Label, Modal, Popup } from "semantic-ui-react";
import needle from "../images/needle.png";

export default function WitchingItem({
  playerUser,
  targetUser,
  timestamp,
  evidence,
  witchingNumber,
  likes,
}) {
  const [isImgOpen, setIsImgOpen] = useState(false);
  return (
    <>
      <Modal
        size="large"
        basic
        open={isImgOpen}
        onOpen={() => setIsImgOpen(true)}
        onClose={() => setIsImgOpen(false)}
      >
        <div className="evidenceModal">
          <Image src={evidence} />
        </div>
      </Modal>
      <div className="witchingRow">
        <div
          className={clsx("witcher", "playerName", playerUser.characterClass)}
        >
          {witchingNumber % 50 === 0 && (
            <Label color="red" pointing="right" className="myLabel">
              {`${witchingNumber}th Witching!`}
            </Label>
          )}
          {playerUser.characterName}
        </div>
        <Popup
          trigger={
            <Image
              src={needle}
              className="needle"
              onClick={() => setIsImgOpen(true)}
            />
          }
        >
          <Popup.Content>
            <div
              className="evidenceContainer"
              style={{
                backgroundImage: `url(${evidence})`,
              }}
            >
              <div className="evidenceDate">
                {timestamp &&
                  DateTime.fromJSDate(timestamp.toDate()).toFormat(
                    "HH:mm, dd/MM/y"
                  )}
              </div>
            </div>
          </Popup.Content>
        </Popup>
        <div
          className={clsx("playerName", "target", targetUser.characterClass)}
        >
          {targetUser.characterName}
          {likes > 0 && (
            <Label
              color="pink"
              pointing="left"
              className="likeLabel"
              size="medium"
            >
              <Icon name="like" />
              <span>{likes}</span>
            </Label>
          )}
        </div>
      </div>
    </>
  );
}
