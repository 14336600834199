import { useEffect, useState } from "react";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import * as firebaseApi from "../api/firebase";
import Dropzone from "../common/Dropzone";
import * as toast from "../toast";

export default function ClaimModal({ isOpen, closeModal, bounty }) {
  const [evidenceError, setEvidenceError] = useState(false);
  const [evidenceFiles, setEvidenceFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEvidenceError(false);
    setEvidenceFiles([]);
  }, [isOpen]);

  function handleEvidenceChanged(files) {
    setEvidenceFiles(files);
  }

  async function handleSubmit() {
    const hasError = evidenceFiles.length === 0;
    setEvidenceError(hasError);
    if (hasError) return;

    setIsLoading(true);
    await firebaseApi.addWitching(
      bounty.targetUser.id,
      evidenceFiles[0],
      bounty.id
    );
    const result = true;
    setIsLoading(false);
    if (!result) {
      toast.error("An error has occured, please try again.");
    } else {
      closeModal();
      toast.success("Bounty claimed successfully!");
    }
  }

  if (!bounty) return null;

  return (
    <Modal open={isOpen} size="tiny">
      <Modal.Header>
        Claim Bounty - {bounty.targetUser.characterName} /{" "}
        {bounty.reward.toLocaleString("en-US")} Gold
      </Modal.Header>
      <Modal.Content>
        <>
          <Message
            icon="info circle"
            content={`By claiming a bounty you will not recieve any witching points. Once you confirm ${bounty.playerUser.characterName} has rewarded you, they will recieve the points.`}
          />
          <Form loading={isLoading}>
            <Form.Field error={evidenceError}>
              <label>Evidence</label>
              <Dropzone
                onChange={handleEvidenceChanged}
                error={evidenceError}
              />
            </Form.Field>
          </Form>
        </>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleSubmit} disabled={isLoading}>
          Submit
        </Button>
        <Button onClick={closeModal} disabled={isLoading}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
