import { useEffect, useState } from "react";
import { Button, Form, Image, Modal } from "semantic-ui-react";
import * as firebaseApi from "../api/firebase";
import ConfirmModal from "../common/ConfirmModal";
import * as toast from "../toast";

const classes = [
  { id: "dk", name: "Death Knight" },
  { id: "dh", name: "Demon Hunter" },
  { id: "druid", name: "Druid" },
  { id: "hunter", name: "Hunter" },
  { id: "mage", name: "Mage" },
  { id: "monk", name: "Monk" },
  { id: "paladin", name: "Paladin" },
  { id: "priest", name: "Priest" },
  { id: "rogue", name: "Rogue" },
  { id: "shaman", name: "Shaman" },
  { id: "warlock", name: "Warlock" },
  { id: "warrior", name: "Warrior" },
];

const realms = [
  { key: "alonsus", value: "alonsus", text: "Alonsus" },
  { key: "anachronos", value: "anachronos", text: "Anachronos" },
  { key: "kul-tiras", value: "kul-tiras", text: "Kul Tiras" },
];

function isEmptyOrSpaces(str) {
  return !str || str.match(/^\s*$/) !== null;
}

export default function RegisterModal({ user }) {
  const [open, setOpen] = useState(true);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(null);
  const [realm, setRealm] = useState(null);
  const [realmError, setRealmError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [characterImage, setCharacterImage] = useState(null);
  const [characterData, setCharacterData] = useState(null);

  useEffect(() => {
    setName(user.characterName);
  }, [user]);

  async function checkForm() {
    let error = false;

    if (isEmptyOrSpaces(name)) {
      error = true;
      setNameError({
        content: "Please enter your character name",
        pointing: "below",
      });
    } else {
      setNameError(null);
    }

    if (!realm) {
      error = true;
      setRealmError({
        content: "Please select a realm",
        pointing: "above",
      });
    } else {
      setRealmError(null);
    }

    if (error) return;

    setLoading(true);

    const characterData = await firebaseApi.getCharacterData(name, realm);

    if (!characterData) {
      toast.error("Character not found.");
    } else {
      const image = await firebaseApi.getCharacterImage(name, realm);
      setCharacterData(characterData);
      setCharacterImage(image);
    }

    setLoading(false);
  }

  async function onConfirm() {
    const characterClass = classes.find(
      (entry) => entry.name === characterData.character_class.name
    ).id;

    const isSuccessful = await firebaseApi.setUserDetails(
      name,
      characterClass,
      realm,
      characterImage
    );

    if (!isSuccessful) {
      toast.error("An error has occured, please try again.");
    } else {
      setOpen(false);
      return true;
    }
  }

  return (
    <Modal open={open} size="tiny">
      {characterData !== null && (
        <ConfirmModal
          isOpen={characterData !== null}
          closeModal={() => setCharacterData(null)}
          onConfirm={onConfirm}
          text={
            <div>
              {name} {characterData.race.name}{" "}
              {characterData.character_class.name}?
              <Image src={characterImage} style={{ margin: "2rem auto 0" }} />
            </div>
          }
        />
      )}
      <Modal.Header>A New Witch Has Arrived!</Modal.Header>
      <Modal.Content>
        <p className="modalText">
          We just need a few more details about you. 🙂
        </p>
        <Form loading={loading}>
          <Form.Field>
            <label>Character Name</label>
            <Form.Input
              value={name}
              onChange={(e, data) => setName(data.value)}
              placeholder="Character Name"
              fluid
              error={nameError}
            />
          </Form.Field>
          <Form.Field>
            <label>Realm</label>
            <Form.Select
              value={realm}
              onChange={(e, data) => setRealm(data.value)}
              placeholder="Realm"
              fluid
              options={realms}
              error={realmError}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={loading}
          content="Ready to witch"
          labelPosition="right"
          icon="checkmark"
          onClick={checkForm}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}
