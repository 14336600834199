import { useState } from "react";
import { Button, Dimmer, Header, Icon, Loader, Modal } from "semantic-ui-react";

export default function ConfirmModal({ isOpen, closeModal, onConfirm, text }) {
  const [isLoading, setIsLoading] = useState(false);

  async function confirm() {
    setIsLoading(true);
    const didClose = await onConfirm();
    if (!didClose) setIsLoading(false);
  }

  return (
    <Modal
      basic
      open={isOpen}
      size="small"
      onClose={() => !isLoading && closeModal()}
    >
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Header size="huge" icon>
        {text}
      </Header>
      <Modal.Content>
        <div className="confirmModalContainer">
          <Button
            color="green"
            inverted
            size="big"
            onClick={confirm}
            style={{ marginRight: "2rem" }}
          >
            <Icon name="checkmark" /> Yes
          </Button>
          <Button color="red" size="big" inverted onClick={closeModal}>
            <Icon name="remove" /> No
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}
