import { useEffect, useState } from "react";
import { Button, Form, Icon, Modal, Popup } from "semantic-ui-react";
import * as firebaseApi from "../api/firebase";
import Dropzone from "../common/Dropzone";
import * as toast from "../toast";

export default function ReportWitching() {
  const [userNames, setUserNames] = useState([]);
  const [bounties, setBounties] = useState([]);
  const [targetBounties, setTargetBounties] = useState([]);
  const [bountyId, setBountyId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [targetId, setTargetId] = useState(null);
  const [targetIdError, setTargetIdError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [evidenceError, setEvidenceError] = useState(false);
  const [evidenceFiles, setEvidenceFiles] = useState([]);

  useEffect(() => {
    setTargetId(null);
    setTargetIdError(null);
    setEvidenceError(false);
    setEvidenceFiles([]);
    setBountyId(null);
    setTargetBounties([]);
  }, [isOpen]);

  useEffect(() => {
    async function getFormData() {
      const names = await firebaseApi.getUserNames();
      const bounties = await firebaseApi.getOpenBounties();

      if (!names || !bounties) {
        toast.error("Something went wrong, please try again later. 😥");
      } else {
        setBounties(bounties);
        setUserNames(names);
      }

      setIsLoading(false);
    }
    getFormData();
  }, [isOpen]);

  function parseBounties(bounties) {
    const parsed = [];
    for (const bounty of bounties) {
      parsed.push({
        key: bounty.id,
        value: bounty.id,
        text: `${bounty.reward.toLocaleString("en-US")} Gold - ${
          bounty.playerUser.characterName
        }`,
      });
    }
    return parsed;
  }

  function handleEvidenceChanged(files) {
    setEvidenceFiles(files);
  }

  function handleTargetChanged(e, data) {
    const targetId = data.value;
    setTargetId(targetId);

    if (targetId) {
      const filteredBounties = bounties.filter(
        (bounty) => bounty.targetUser.id === targetId
      );
      setTargetBounties(parseBounties(filteredBounties));
    } else {
      setTargetBounties([]);
    }
  }

  async function handleSubmit() {
    let hasErrors = false;
    if (!targetId) {
      setTargetIdError({
        content: "Please select your witching target",
        pointing: "below",
      });
      hasErrors = true;
    } else setTargetIdError(null);

    if (evidenceFiles.length === 0) {
      setEvidenceError(true);
      hasErrors = true;
    } else setEvidenceError(false);

    if (hasErrors) return;

    setIsLoading(true);
    const result = await firebaseApi.addWitching(
      targetId,
      evidenceFiles[0],
      bountyId
    );
    setIsLoading(false);
    if (!result) {
      toast.error("An error has occured, please try again.");
    } else {
      setIsOpen(false);
      toast.success("Witching reported successfully!");
    }
  }

  return (
    <>
      <Button inverted color="red" onClick={() => setIsOpen(true)}>
        <Icon name="quidditch" /> Report Witching
      </Button>
      <Modal open={isOpen} size="mini">
        <Modal.Header>Oops!... I Witched Him Again</Modal.Header>
        <Modal.Content>
          <Form loading={isLoading}>
            <Form.Field>
              <label>Target</label>
              <Form.Dropdown
                options={userNames}
                fluid
                selection
                placeholder="Target player"
                search
                onChange={handleTargetChanged}
                error={targetIdError}
              />
            </Form.Field>
            <Form.Field disabled={targetBounties.length === 0}>
              <label>
                Bounty{" "}
                <Popup trigger={<Icon name="info circle" />} inverted>
                  <Popup.Content>
                    By claiming a bounty you will not recieve any witching
                    points. Once you confirm you got your reward, whoever placed
                    the bounty will recieve the points.
                  </Popup.Content>
                </Popup>
              </label>
              <Form.Dropdown
                clearable
                options={targetBounties}
                fluid
                selection
                placeholder="No Bounty"
                onChange={(e, data) => setBountyId(data.value)}
              />
            </Form.Field>
            <Form.Field error={evidenceError}>
              <label>Evidence</label>
              <Dropzone
                onChange={handleEvidenceChanged}
                error={evidenceError}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary disabled={isLoading} onClick={handleSubmit}>
            Submit
          </Button>
          <Button disabled={isLoading} onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
